import type {
  CheckboxField,
  ConditionsField,
  Rules,
} from '~/components/BaseForm/types'

type InputCheckbox = {
  inputType: 'checkbox'
  fields?: CheckboxField[]
  conditions?: ConditionsField
  containerInputClass?: string
  hidden?: boolean
  viewModeRow?: boolean
  order?: number
  attr: {
    bgCheckboxClass?: string
    customHtml?: boolean
    disabled: boolean
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey: string
    name: string
    color?: string
    placeholderTranslateKey?: string
    rules?: Rules
    textCheckboxClass?: string
    vertical?: boolean
    wrapperClass?: string
  }
}

const inputCheckbox: InputCheckbox = {
  inputType: 'checkbox',
  attr: {
    name: 'checkbox',
    labelTranslateKey: 'form.label',
    rules: 'required',
    disabled: false,
  },
}

export default inputCheckbox
